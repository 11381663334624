import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/members",
    name: "members",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/./MembersView")
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/CalendarView.vue")
  },
  {
    path: "/results",
    name: "results",
    component: () => import("../views/ResultsView.vue")
  },
  {
    path: "/dictionary",
    name: "dictionary",
    component: () => import("../views/DictionaryView.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue")
  },
  {
    path: "/regulations",
    name: "regulations",
    component: () => import("../views/RegulationsView.vue")
  },
  {
    path: "/about_us",
    name: "about_us",
    component: () => import("../views/AboutAsView.vue")
  },
  {
    path: "/gobus",
    name: "gobus",
    component: () => import("../views/GoBusView.vue")
  },
  {
    path: "/gohelper",
    name: "gohelper",
    component: () => import("../views/GoHelperView.vue")
  },
  {
    path: "/goclock",
    name: "goclock",
    component: () => import("../views/GoClockView.vue")
  },
  {
    path: "/gohelper/privacy_policy",
    name: "privacy_policy",
    component: () => import("../views/PrivacyPolicy/GoHelperPrivacyPolicyView.vue")
  },
  {
    path: "/goclock/privacy_policy",
    name: "privacy_policy",
    component: () => import("../views/PrivacyPolicy/GoClockPrivacyPolicyView.vue")
  },
  {
    path: "/our_achievements",
    name: "our_achievements",
    component: () => import("../views/OurAchievements.vue")
  },
  {
    path: "/league",
    name: "league",
    component: () => import("../views/League/LeagueView.vue")
  },
  {
    path: "/youth_go_academy",
    name: "youth_go_academy",
    component: () => import("../views/YouthGoAcademyView.vue")
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
